import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentText from '@solid-ui-components/ContentText'
import React from 'react'
import { Container } from 'theme-ui'
const StatsBlock02 = ({
  content: { text, container, collection, buttons, images }
}) => (
  <Container sx={{ textAlign: `left` }}>
    <ContentText
      content={text[0]}
      sx={{ textAlign: 'center', marginBottom: 50 }}
    />
    <ContentImages
      content={{ images }}
      loading='eager'
      // reverse={reverse}
      imagePosition='center'
    />
    <ContentText
      sx={{ marginTop: 30 }}
      content={text.filter((e, i) => i != 0)}
    />
  </Container>
)

export default WithDefaultContent(StatsBlock02)
